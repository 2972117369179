.navbarWrapper {
    background-color: #8aaae5;
    color: white;
    border: 0.1rem solid;
    padding: 0.65rem 2rem;
    line-height: 1.5;
    font-size: 0.95rem;
    border-radius: 0.3rem;
    font-family: "Source Sans Pro", "Segoe UI", "Helvetica Neue", "Arial";
    text-align: center;
    margin-bottom: 1.5rem !important;
} 