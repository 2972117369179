h1 {
    color: #000000 !important;
    font-family: "sans-serif", "Georgia";
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

h2 {
    font-size: 2rem;
    font-family: "sans-serif", "Georgia";
    font-weight: 500;
    line-height: 1.2;
    color: #000000;
    margin-bottom: 0.5rem;
}

p {
    font-size: 1.3rem;
    font-family: "sans-serif", "Georgia";
    font-weight: 400;
    color:  #000000;
    margin-bottom: 2rem;
}

body {
    text-align: left;
    text-size-adjust: 100%;
}