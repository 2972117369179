.mainContainer {
    width: 80%;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
    padding-bottom: 1.5rem !important;
    box-sizing: border-box;
    line-height: 1.6;
    display: block;
}