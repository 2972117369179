/* Styling for the root element, to be imported directly
   as a CSS file instead of as a CSS module with mangled 
   class names.

   Included here instead of index.css because the styling 
   directly concerns the display of items in the App component, 
   and it used to be part of the App <style jsx> element.
*/
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
